import { PageType } from 'components';
import { contentapi } from 'library/contentapi';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { PageTypeProps } from 'Online/context/PageContext';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import { logger } from 'Shared/utilities';

export interface PageContentProps {
	content: PageTypeProps;
}

const Page: NextPage<PageContentProps> = ({ content }) => {
	return <PageType {...content} />;
};

export const getStaticPaths: GetStaticPaths = async () => {
	const paths = [];
	const fallback = 'blocking';

	return { paths, fallback };
};

export const getStaticProps: GetStaticProps = async ({
	params,
	preview,
	previewData,
}: {
	previewData: PreviewData;
	preview: boolean;
	params: ParsedUrlQuery;
}) => {
	const { host }: { host?: string } = params ?? {};
	const url = params.page ? (params.page as string[]).join('/') : '';

	try {
		const content = await contentapi.getPageContent({ url, host, preview, previewData });

		const { culture } = content.page;

		const pageSettings = content.page.properties.pageSettings;
		const navigationSettings = pageSettings.find((setting) => {
			return setting.documentType === 'navigation';
		});
		const { redirect } = navigationSettings?.content?.properties ?? {};
		if (redirect) {
			return {
				redirect: {
					statusCode: 302,
					destination: redirect.url,
				},
			};
		}
		return {
			props: {
				content,
				culture: culture,
				siteSettings: content.root.properties.siteSettings,
				root: content.root,
				dictionary: content.dictionary,
			},
			revalidate: 1,
		};
	} catch (err) {
		if (err?.statusCode === 301 || err?.statusCode === 302) {
			return {
				redirect: {
					statusCode: err.statusCode,
					destination: err.url,
				},
			};
		}

		if (err?.statusCode === 404) {
			return {
				notFound: true,
			};
		}

		if (err?.statusCode === 500) {
			logger.debug(
				`page.tsx - Error is probably caused by incorrect configuration of Umbraco hostname (missing / in the end)`,
			);
		}

		logger.debug(`${host} - ${err} `);

		throw new Error(err);
	}
};

export default Page;
